.navbar {
    background: #021824;
    display: flex;
    height: 120px;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #c6e992;
    box-shadow: 0px 10px 10px black;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    margin-bottom: 15%;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mt-60p{
    margin-top: 60px;
  }
  
  .navbar-logo {
    width: 51px;
    height: 84px;
    padding-left: 30px;
  }

  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .nav-links {
    color: #c6e992;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #4c0409;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #c6e992;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .connect-btn {
    width: 50%;
    height: 60px;
    margin-left: 5px;
    padding: 10px;
    color: #000000;
    background-color: #e59e4e;
    border: 0;
    font-weight: 600;
    font-size: 1.5rem;
    position: relative;
  }

  .flex-container-inkz {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .inkz-border {
    border-color: #c6e992;
    padding-top: 12px;
    padding-bottom: 12px;
    min-width: 120px;
    border-style: solid;
    border-width: 2px;
    background-color: #072232;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 30vh;
      position: absolute;
      top: 100px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #4c0409;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #021824;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, 20%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 120%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #021824;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #021824;
      transition: 250ms;
    }

    .connect-btn {
      width: 30%;
      margin-left: 5px;
      padding: 8px;
      color: #000000;
      background-color: #e59e4e;
      border: 0;
      font-weight: 600;
      font-size: .75rem;
      position: relative;
    }
    .flex-container-inkz {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    }
    .inkz-border {
      border-color: #c6e992;
      min-width: 120px;
      border-style: solid;
      border-width: 2px;
      background-color: #072232;
    }
  }