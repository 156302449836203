@font-face {
  font-family: "PressStart2P";
  src: local(PressStart2P),
   url(./fonts/PressStart2P.ttf) format("truetype");
  font-weight: bold;
  }
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

body {
  background-color: #4c0409;
  margin: 0;
  font-family: "PressStart2P";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: "PressStart2P";
}

button {
  font-family: "PressStart2P";
}